<template>
  <section class="m-followed m-scroll">

    <!-- 头部导航 -->
    <header class="van-hairline--bottom">
      <img @click="$router.go(-1)" src="@/assets/icon_back@2x.png" alt="">
      <p>Followed Stores</p>
      <span slot="right" class="filter-edit">
        <span v-if="!showAll" @click="handleEdit">Edit</span>
        <span v-else @click="HandleAll">Done</span>
      </span>
    </header>

    <!-- 关注列表 -->
    <van-list  v-model="loading" :immediate-check="false"	  :finished="finished" finished-text="No More" class="list">
      <van-swipe-cell v-for="(it,index) in followList" :key="index" :disabled="disabled">

        <van-checkbox v-model="it.checked" ref="item" checked-color="#ED2A24" class="item-check">
          <div class="item-wrap" @click="$router.push('/zh/merchant/index/' + it.shops_id)">
            <div class="avatar" v-if="it.shops_info"><img :src="it.shops_info.pic_url" alt="img"></div>
            <div class="info van-hairline--bottom" v-if="it.shops_info">
              <p>{{it.shops_info.title}}</p>
              <p style="margin-top:20px">{{it.shops_info.collect_num}} Followed</p>
            </div>
            
          </div>
        </van-checkbox>
        <template #right>
          <div class="delete" @click="cancleFollowHandle(it.shops_id)">Cancel Follow</div>
        </template>
      </van-swipe-cell>

    </van-list>
  
    <!-- 底部全选 取消关注 -->
    <transition name="fade">
      <div v-show="showAll" class="fixed-bar m-padding">
          <van-checkbox v-model="checkedAll" @click="handleCheckAll" checked-color="#ED2A24">All</van-checkbox>
          <span @click="cancleFollowHandle(-1)">Cancel Follow</span>
      </div>
    </transition>   

  </section>
</template>

<script>
import { getFollowed, cancleFollow } from '@/api/zh/mine.js'
export default {
  name:'Index',
  components:{ },
  data(){
    return {
      loading:false,
      finished:false,
      showAll:false,     // 是否显示全选
      disabled:false,    // 是否禁止滑动
      filter:false,      // 筛选弹窗 
      filterType:0,
      followList: []
    }
  },

  computed: {
    checkedAll: {
      set(){
        return false
      },
      get(){
        let bol = false
        this.followList.forEach(e => {
          if(!e.checked){
            bol = true
          }
        })
        if(bol){
          return false
        }else{
          return true
        }
      }
    },
  },

  methods:{
    // 点击头部编辑
    handleEdit(){
      this.showAll  = true 
      this.disabled = true
      // console.log(this.$refs.item[0])
      this.$refs.item.forEach(e => {
        e.$el.style.left = '0px'
      })
    },
    // 点击头部完成
    HandleAll(){
      this.showAll  = false 
      this.disabled = false
      this.$refs.item.forEach(e => {
        e.$el.style.left = '-52px'
      })
    },
    // 点击全选
    handleCheckAll(){
      if(this.checkedAll){
        this.followList.forEach(e => {
          e.checked = false
        })
      }else{
        this.followList.forEach(e => {
          e.checked = true
        })
      }
    },
    getFollowedHandle() {
      // 获取店铺关注
      this.finished = false
      this.followList = []
      getFollowed().then(res => {
        res.data.data.forEach(el => {
          el.checked = false
        })
        this.followList = res.data.data
      }).finally(() => {
        if(this.followList.length == 0) {
          this.finished = true
        }
      })
    },
    cancleFollowHandle(id = -1) {
      // 取消关注
      let shopIdArr = []
      if(id != -1) {
        shopIdArr = [id]
      } else {
        this.followList.forEach(el => {
          if(el.checked) {
            shopIdArr.push(el.shops_id)
          }
        })
      }
      cancleFollow(shopIdArr.join(','), {status: 0}).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.getFollowedHandle()
          this.showAll = false
        }
      })
    }
  },
  created() {
    this.getFollowedHandle()
  }
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>
